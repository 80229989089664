.titrelocchimio{
  font-size: 170%;
  margin-bottom: 10px;
  font-weight: bold;
  color: #241f56;
  border: none;
  border-color: #6194ed;
  text-decoration: underline;
}

.pagemenu{
  display: flex;
  flex-direction: row ;
  margin-top: 35px;

}

.menugauche{
  display: flex;
  flex-direction:column ;
  justify-content:  flex-start; 
  width: 66%;
}

.menudroite{
  display: flex;
  flex-direction:column ;
  justify-content:  flex-start; 
  width: 33%;
}

.ligne{
  display: flex;
  flex-direction:row ;
  min-width: 100%;
  margin-bottom: 35px;
}

.lg{
  display: flex;
  flex-direction:column ;
  justify-content:  flex-start ;
  align-items: center ; 
  min-width: 50%;

}

.ld{
  display: flex;
  flex-direction:column ;
  justify-content:  flex-start ;
  align-items: center ; 
  min-width: 50%;
}

.lx{
  display: flex;
  flex-direction:column ;
  justify-content:  flex-start ;
  align-items: center ; 
  min-width: 100%;
}




.logopageaccueil{
  width: 12%;
  height: 8%;
}

.logolien{
  width: 100%;
  height: 100%;
}

.textlien {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  height: 100%;
  width: 100%;
}

.divlien {
  display: flex;
  align-items: center ;
  justify-content: center ;
  height: 100%;
  width: 100%;
}

a.lienaccueil {
  text-decoration: none;
  color: #1F3356;
  font-size: 2em;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center ;
  justify-content: center ;

}


a:link {

}

a:visited {

}

a:focus {

}

a.lienaccueil:hover {
  color: #F4F8FC;
  background-color: #1F3356;
  text-decoration: none;
}

a:active {

}

a.lienonco {
  display: flex;
  text-decoration: none;
  color: #1F3356;
  font-size: 110%;
  display: flex;
  align-items: center ;
  justify-content: center ;
  margin-top: 0px;
  height: 40px;
  min-width: 100%;
}

a.lienonco:hover{
  color: #F4F8FC;
  background-color: #1F3356;
  text-decoration: none;
}

.divlien{
    /* border-style: solid; */
    width: 31%;
}

.divpageacceuil {
  margin-top: 100px;
  /* border-style: solid; */
  display: flex;
  flex-direction: row;
  justify-content: space-around; 
  height: auto;
  min-height: 550px;
}

.divdroite {
  /* border-style: solid; */
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: space-around; 
}

.divpagetexte {
  /* border-style: solid; */
  width: 49%;
}

.divpagelien {
  /* border-style: solid; */
  height: 49%;
}

.divpageexemple {
  /* border-style: solid; */
  height: 49%;
}

.divpagelien {
  display: flex;
  flex-direction: row;
  justify-content: space-around; 
}

.textev1 {
  margin-bottom: 15px;
  color: #1F3356;
  font-family: 'Poppins', sans-serif;
  font-size: 3em;
  font-weight: bold;
}

.textev2 {
  margin-bottom: 10px;
  color: #70768A;
  font-family: 'Poppins', sans-serif;
  font-size: 1,7em;
}

a:hover {
  background-color: #1f2a56;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.champ {
  height: auto;
  width: 50%;
  max-width: 50%;
  min-width: 50%;
  min-height: 100px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #F4F8FC;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; */
/* } */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 
ONCO PAGE */

.divpageonco {
  display: flex;
  flex-direction:row ;
  justify-content: space-around; 
  margin-top: 70px;
  min-height: 900px;
}

.divoncocolonne{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center ;
  justify-content: center ;
  font-size: 130%;
  font-weight: bold;


}



.titrecolonne{
  margin-bottom: 20px;
  font-size: 145%;
  /* border-style: solid;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px; */
}

/* PAGE CS */

.App {
  display: flex;
  flex-direction:column ;

  background-color: #F4F8FC;
  color: #1F3356;
  min-height: 100%;
  height: 100%;
  max-height: none;
  margin: 0px;
  padding: 0px;
}

.Header{
  position: fixed;
width: 100%;
z-index: 1;
height: 55px;
background-color: #343A40;
text-align: center;
font-size: 90%;

}

.texte_header_bold{
  color: white;
  font-weight: bold;
  font-size: 100%;
}

.texte_header{
  color: white;
}


.droite{
  /* border-style: solid; */
  display: flex;
  max-width: 44%;
  min-width: 44%;
  min-height: 100%;

}

.droiterectangle{
  font-weight: bold;
  position: fixed;
  height: 92%;
  width: 50%;
  overflow: scroll;
  border: red;
  font-size: 13px;  
}

.gauche{
  display: flex;
  flex-direction:column ;
  justify-content: space-around; 
  width: 54%;
  
  
  /* 
  font-weight: bold;
  display: flex;
  position: fixed;
  flex-direction: column;
  height: 92%;
  width: 50%;
  overflow: scroll;
  border: red;
  font-size: 13px;   
  */
  
  }


/* .gaucherectangle{
   margin-top: 610px;
  font-weight: bold;
   position: fixed;
   height: 90%;
  width: 55%; 
  overflow: scroll;
  border: red;
  font-size: 13px;
} */


.droitegauche{
  margin-top: 60px;
  display: flex;
  flex-direction:row ;
  justify-content: space-around; 
  max-height: 100%;
  min-height: 100%;
  /* height: 91%; */
  overflow: scroll;
}



.gauchesection{
display: flex;
flex-direction:row ;
justify-content: flex-start;
/* border-style: solid; */
margin-top: 0px;
/* border: 1px solid black;  */
}



.gauche100{
  /* border-style: solid; */
  width: 99%;  
  margin-right: 10px;
}

.gauche66{
  /* border-style: solid; */
  width: 65%;  
  margin-right: 10px;
}

.gauche50{
  display: flex;
  flex-direction:column ;
  width: 49%;  
  /* border-style: solid; */
  margin-right: 10px;
}

.gauche33{
  display: flex;
  flex-direction:column ;
  width: 32%; 
  /* border-style: solid;  */
  margin-right: 10px;
}

.gauche37{
  display: flex;
  flex-direction:column ;
  width: 37%; 
  /* border-style: solid;  */
  margin-right: 10px;
}
.gauche16{
  display: flex;
  flex-direction:column ;
  width: 16%; 
  /* border-style: solid;  */
  margin-right: 10px;
}

.gauche25{
  display: flex;
  flex-direction:column ;
  width: 24%; 
  /* border-style: solid;  */
  margin-right: 10px;
}

.gauche14{
  display: flex;
  flex-direction:column ;
  width: 14%; 
  /* border-style: solid;  */
  margin-right: 10px;
  
}

.gauche20{
  display: flex;
  flex-direction:column ;
  width: 20%; 
  /* border-style: solid;  */
  margin-right: 10px;
  
}


.titreacc{
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color:#AC5900;
  font-size: 1,5em;
}

.accordion{
  width: 100%;
  margin-bottom: 20px;

}

.titreitem{
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #030303;
  font-size: 1,2em;
  text-decoration: underline;
  margin-top: 10px;
  /* border: 1px solid black; */
}

.titreitem_2{
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #030303;
  font-size: 1,2em;
  text-decoration: underline;
  margin-top: 30px;
  /* border: 1px solid black; */
}

/* // BOUTON */

.btn-info {
  background-color: #F4F8FC !important;
  color: #1F3356 !important;
  min-width: 100%;
  margin-bottom: 4px;
  height: 32px;
  border-color: #1F3356 !important;
  display: flex;
  align-items: center;
}

.btn-info:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-success {
  background-color: #1F3356!important;
  color: #F4F8FC !important;
  min-width: 100%;
  margin-bottom: 4px;
  border-color: #1F3356 !important;
  height: 32px;
  display: flex;
  align-items: center;
}

.btn-success:focus {
  outline: none !important;
  box-shadow: none !important;
}

input.champ{
  min-width: 100%;
  min-height: 35px;
  margin-bottom: 4px;
  border-color: #1F3356;
  border-width: 1px;
}

input.champ_long{
  min-width: 100%;
  min-height: 70px;
  margin-bottom: 4px;
  border-color: #1F3356;
  border-width: 1px;
}

input.number1{
  max-width: 50%;
  max-width: 50%;
  min-height: 23px;
  max-height: 23px;
  margin-bottom: 4px;
  border-color: #1F3356;
  border-width: 1px;
}

input.date1{
  max-width: 75%;
  max-width: 75%;
  min-height: 23px;
  max-height: 23px;
  margin-bottom: 4px;
  border-color: #1F3356;
  border-width: 1px;
}

input.text1{
  max-width: 100%;
  max-width: 100%;
  min-height: 23px;
  max-height: 23px;
  margin-bottom: 4px;
  border-color: #1F3356;
  border-width: 1px;
}

input.input1{
  min-width: 100%;
  min-height: 23px;
  max-height: 23px;
  margin-bottom: 4px;
  border-color: #1F3356;
  border-width: 1px;
}

p.titrebouton{
  display: flex;
  justify-content: center ;
  color: #1F3356;
  font-weight: bold;
}

p.titrebouton_2{
  display: flex;
  justify-content: center ;
  color: #1F3356;
  font-weight: bold;
  margin-top: 10px;
}

.inputbioligne{
  display: flex;
  flex-direction: row ;
  max-height: 35px;
  align-items: center ;
}

.texteinputbio{
  margin-top: 10px;
  margin-right: 5px;
}

.texteinputbiogras{
  margin-top: 10px;
  margin-right: 5px;
  font-weight: bold;
}

.lignebio{
  /* display: flex;
  flex-direction: row ; */
}

textarea.champ{
  min-width: 100%;
  min-height: 50px;
  margin-bottom: 4px;
  border-color: #1F3356;
  border-width: 1px;
}

textarea.champ_long{
  min-width: 100%;
  min-height: 130px;
  margin-bottom: 4px;
  border-color: #1F3356;
  border-width: 1px;
}

textarea.zonetextedroite{
  display: flex;
  min-width: 90%;
  max-width: 90%;
  min-height: 90%;
  max-height: 90%;
  margin-bottom: 4px;
  border-color: #1F3356;
  border-width: 1px;
  color: #1f2a56;
  
}



/* SELECTOR */
/* 
.selector{
  display: flex;
  flex-direction: column ;
}

.selectortitre{
  display: flex;
}

.selectorcorps{
  display: flex;
  flex-direction: row ;
}

.selectornom{
  display: flex;
}

.selectorinput{
  display: flex;
}

.selectorbouton{
  display: flex;
  flex-direction: column ;
}

.selectorligne{
  display: flex;
  flex-direction: row ;
} */

/* DROITE */

.border border-primary{
}



/* .gaucherectangle{
  font-weight: bold;
  display: flex;
  position: fixed;
  overflow: scroll;
  height: 91%;
  font-size: 13px;  
} */


img.photocopy:hover {
  background-color: #466cad;
  width: 35px;
}

img.photocopy {
  width: 35px;
}

img.photocopy:hover:link { color: red; }

.barreduhaut{
  display: flex;
  flex-direction: row ;

}

p.titredroite{
  display: flex;
  justify-content: center ;
  align-items: center ;
  color: #1F3356;
  font-weight: bold;
  font-size: 120%;
  margin-left: 10px;
}

.zonerendutexte{
  min-width: 90%;
  max-width: 90%;
  min-height: 90%;
  max-height: 90%;
  margin-bottom: 4px;
  border: solid;
  border-color: #1F3356;
  background-color: white;
  border-width: 1px;
  color: black;
  line-height : 17px;
  padding-top: 15px;
  padding-left: 5px;
  white-space: Normal;
  overflow: scroll;
  font-weight: normal;

}

.grasetsouligne{
  font-weight: bold;
  text-decoration: underline;
}

.souligne{
  text-decoration: underline;
}


.TwoHeader{
  position: fixed;
}

.vertical-text {
  display: inline-block; /* Permet d'appliquer une largeur */
  width: 25px; /* Fixe la largeur à 15px */
  margin-top: 190px;
  transform: rotate(270deg); /* Tourne le texte de 90 degrés */
  transform-origin: left top 0; /* Point de rotation */
  white-space: nowrap; /* Empêche le texte de passer à la ligne */
  font-size: 85%;
}

.vertical-text_bio {
  display: inline-block; /* Permet d'appliquer une largeur */
  width: 25px; /* Fixe la largeur à 15px */
  margin-top: 20px;
  transform: rotate(0deg); /* Tourne le texte de 90 degrés */
  transform-origin: left top 0; /* Point de rotation */
  white-space: nowrap; /* Empêche le texte de passer à la ligne */
  font-size: 85%;
  font-style: italic;
}

.vertical-text_dose{
  display: inline-block; /* Permet d'appliquer une largeur */
  width: 25px; /* Fixe la largeur à 15px */
  margin-top: 0px;
  margin-bottom: 15px;
  transform: rotate(0deg); /* Tourne le texte de 90 degrés */
  transform-origin: left top 0; /* Point de rotation */
  white-space: nowrap; /* Empêche le texte de passer à la ligne */
  font-size: 85%;
  font-style: italic;
}

.gauchesection_conclu{
  display: flex;
  flex-direction:row ;
  justify-content: flex-start;
  /* border-style: solid; */
  margin-top: 0px;
  margin-top: 0px
  ;
  }

  .zone_biologie{
    display: flex;
    flex-direction:row ;
    justify-content: flex-start;
    /* border-style: solid; */
    margin-top: 0px;
    margin-top: 0px
  }

  pre {
    margin: 0px; /* Marge extérieure */
    padding: 0px; /* Marge intérieure */
    margin-bottom: 0px;
    margin-top: 0px;
    line-height: 0; /* Réduit l'espacement entre les lignes */
    border-style: solid;
  }
  